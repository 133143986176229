
  import BotbuilderApi from 'models/botbuilder_api'
  import ContextUpdate from 'models/context_update'
  import DialogNode from 'models/dialog_node'
  import Operation from 'models/operation'
  import Project from 'models/project'
  import { confirm } from 'helpers'

  import ConditionEditor from 'components/botbuilder/condition_editor.vue'
  import ContextUpdateEditor from 'components/botbuilder/context_update_editor.vue'
  import ContextParameterSelector from 'components/botbuilder/inputs/context_parameter_selector.vue'
  import PayloadEditor from 'components/botbuilder/nlg/payload_editor.vue'
  import UniversalInput from 'components/botbuilder/inputs/universal_input.vue'
  import { ActionMenu, Button, CollapsibleCard, Input, KeyListbox, Label } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: Operation
      dialogNode: DialogNode

    emits: ['update:modelValue', 'remove']

    data: ->
      operation: @modelValue
      workflow: null
      languageVariations: Project.LANGUAGE_VARIATIONS
      actionMenuItems:
        [
          icon: XIcon
          label: 'Delete'
          method: =>
            return unless await confirm('Do you really want to delete this operation?')
            @$emit('remove')
        ]

    computed:
      contextParametersForContentSearch: ->
        @$root.contextParameters
          .filter (cp) => cp.key.startsWith('contentSearch' + '.' + @operation.entitySchemaKey + '.')
          .sortByString('label')
      workflowInputMapping: ->
        Object.fromEntries(
          @operation.inputBindings.map (inputBinding) =>
            [
              inputBinding.workflowVariableKey
              @workflow?.trigger.botInput.find((input) -> input.key == inputBinding.workflowVariableKey) || {}
            ]
        )

    watch:
      modelValue: ->
        @operation = @modelValue

    created: ->
      @setWorkflow()

    methods:
      setWorkflow: ->
        return if @operation.type != 'InvokeWorkflow'
        BotbuilderApi.getWorkflow(@operation.workflowId)
          .then (workflow) =>
            @workflow = workflow
            if @operation.setOutputKeys(@workflow)
              @update()
      edit: ->
        @$refs.card.open()
      update: ->
        @$emit('update:modelValue', @operation)
      updateLanguage: ->
        if !@$root.languageVariations[@operation.language]?.includes('EASY')
          @operation.languageVariation = 'DEFAULT'
        @update()
      addSearchParameter: ->
        @operation.searchParameters.push('')
      removeSearchParameter: (index) ->
        @operation.searchParameters.splice(index, 1)
        @update()

    components:
      ActionMenu: ActionMenu
      Button: Button
      CollapsibleCard: CollapsibleCard
      ConditionEditor: ConditionEditor
      ContextParameterSelector: ContextParameterSelector
      ContextUpdateEditor: ContextUpdateEditor
      Input: Input
      KeyListbox: KeyListbox
      Label: Label
      PayloadEditor: PayloadEditor
      UniversalInput: UniversalInput
      XIcon: XIcon
